"use client";
import { cx, defaultRegion, regions } from "@tracksuit/utils";
import { RichText } from "$src/components/rich-text/rich-text";
import { sanityImage } from "$src/lib/sanity";
import { sentry } from "$src/lib/sentry";
import { useEffect, useMemo, type ComponentProps } from "react";
import { usePathname } from "next/navigation";
import styles from "./error-page.module.css";
import type { SanityImageAssetDocument } from "next-sanity";
import type Error from "next/error";

export type ErrorPageProps = {
  icon?: SanityImageAssetDocument;
  error?: Error;
  text: any;
} & ComponentProps<"div">;

/**
 * @component
 * error and not found page templates
 */
export function ErrorPage({
  icon,
  text,
  error,
  className,
  ...props
}: ErrorPageProps) {
  const slug = usePathname().match(/[^/]+/);
  let region = useMemo(
    () => regions.find((region) => slug && region.value === slug[0]),
    [slug]
  );

  useEffect(() => {
    sentry?.captureException(error);
  }, [error]);

  return (
    <div className={cx(className, styles.error)} {...props}>
      <img alt="" {...sanityImage(icon)} />
      <RichText region={region?.value ?? defaultRegion} content={text} />
    </div>
  );
}
