import groq from "groq";
import { videoFragment } from "./video";
import { linkFragment } from "./link";

export const richtextFragment = groq`
  ...,
  asset->,
  thumbnail {
    asset->
  },
  video {
    ${videoFragment}
  },
  markDefs[]{
    ...,
    ${linkFragment}
  },
  copy[] {
    ...,
    markDefs[]{
      ...,
      ${linkFragment}
    },
  }
`;
