import groq from "groq";

export const linkFragment = groq`
  externalLink,
  internalLink -> {
    _type,
    slug
  }
`;

export const labeledLinkFragment = groq`
  label,
  link {
    ${linkFragment}
  }
`;
