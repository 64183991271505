import { cx } from "@tracksuit/utils";
import type { ComponentProps } from "react";
import { HubspotForm } from "../hubspot-form/hubspot-form";
import styles from "./subscribe.module.css";
import { customAlphabet } from "nanoid";

type SubscribeProps = {
  title: string;
  subtitle?: string;
  hubspotFormPortalId: string;
  hubspotFormFormId: string;
  footer: boolean;
  GAEventName?: string;
  noLabels?: boolean;
  headingLevel?: number;
} & ComponentProps<"div">;

/**
 * @component
 * subscription component with email input field
 */
export function Subscribe({
  title,
  subtitle,
  hubspotFormPortalId,
  hubspotFormFormId,
  footer,
  className,
  GAEventName,
  headingLevel = 3,
  noLabels,
  ...props
}: SubscribeProps) {
  const Heading = `h${headingLevel}` as keyof JSX.IntrinsicElements;
  return (
    <section
      className={cx(
        className,
        footer
          ? styles["footer-subscription-container"]
          : styles["subsciption-container"]
      )}
      {...props}
    >
      <div
        className={cx(
          styles["text-container"],
          !footer && styles["text-container-media-queries"]
        )}
      >
        <Heading
          className={cx(styles.title, footer && styles["footer-title"])}
          data-testid={"title"}
        >
          {title}
        </Heading>
        {subtitle && (
          <p
            className={cx(footer ? styles["footer-text"] : styles.text)}
            data-testid={"text"}
          >
            {subtitle}
          </p>
        )}
      </div>
      <div className={cx(!footer && styles["sign-up"])}>
        <HubspotForm
          portalId={hubspotFormPortalId}
          formId={hubspotFormFormId}
          id={customAlphabet("abcdefghijklmnopqrstuvwxyz", 6)()}
          className={"shorts-form"}
          GAEventName={GAEventName}
          noLabels={noLabels}
        />
      </div>
    </section>
  );
}
