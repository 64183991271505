/* istanbul ignore file: Just configures well tested PortableText vender component */
import { Callout } from "../callout/callout";
import { Heading } from "../heading/heading";
import { PortableText } from "@portabletext/react";
import { resolveLink, sanityImage } from "$src/lib/sanity";
import { Subscribe } from "../subscribe/subscribe";
import { TestimonialCarousel } from "../testimonial-carousel/testimonial-carousel";
import { Video } from "$src/components/video/video";
import Link from "next/link";
import styles from "./rich-text.module.css";
import subscribeStyles from "../subscribe/subscribe.module.css";
import { A11Y_EXTERNAL_LINK_LABEL } from "@tracksuit/utils";

export function RichText({ region, content, settings }: any) {
  return (
    <PortableText
      value={content}
      components={{
        types: {
          image: ({ value }: any) => (
            <img
              alt=""
              {...sanityImage(value.asset)}
              className={styles.image}
            />
          ),
          video: ({ value }) => (
            <div className={styles.video}>
              {value.title && <Heading level={3}>{value.title}</Heading>}
              {value.video && value.thumbnail && (
                <Video
                  thumbnail={
                    value.thumbnail && sanityImage(value.thumbnail)?.src
                  }
                  videoId={value.video?.asset?.playbackId}
                />
              )}
            </div>
          ),
          testimonial: ({ value }) => (
            <TestimonialCarousel {...value} large={true} spacing={false} />
          ),
          callout: ({ value }) => (
            <Callout
              region={region}
              color={value?.color}
              content={value?.copy}
            />
          ),
          subscribe: ({ value }) => (
            <Subscribe
              className={subscribeStyles["--nested-in-article"]}
              title={value.title ?? settings.newsletter.title}
              subtitle={value.subtitle ?? settings.newsletter.description}
              hubspotFormPortalId={settings.newsletter.hubspotForm.portalId}
              hubspotFormFormId={settings.newsletter.hubspotForm.formId}
              headingLevel={value.headingLevel}
              footer={false}
            />
          )
        },
        marks: {
          link: ({ value, children }: any) => {
            const href = resolveLink(region, value);
            const external =
              value?.externalLink &&
              value?.externalLink.indexOf("gotracksuit.com") === -1;
            const isValidLink = href.indexOf("undefined") === -1;

            return (
              <>
                {isValidLink ? (
                  <Link
                    href={href}
                    target={external ? "_blank" : undefined}
                    rel={external ? "noopener" : undefined}
                    className={styles.link}
                  >
                    {children}
                    {external && (
                      <span className="sr-only">
                        {A11Y_EXTERNAL_LINK_LABEL}
                      </span>
                    )}
                  </Link>
                ) : (
                  <span>{children}</span>
                )}
              </>
            );
          }
        }
      }}
    />
  );
}
