"use client";

import { ErrorPage } from "$src/components/error-page/error-page";
import { sanity } from "$src/lib/sanity";
import { richtextFragment } from "$src/fragments/richtext";
import type { SanityImageAssetDocument } from "next-sanity";
import groq from "groq";
import { useEffect, useState } from "react";
import type Error from "next/error";

type ErrorDataType = {
  errorIcon: {
    asset: SanityImageAssetDocument;
  };
  errorText: string;
};

export default function ErrorLandingPage(error: Error) {
  const [errorData, setErrorData] = useState<ErrorDataType>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await sanity.fetch(groq`*[_type == "error"][0]{
          ...,
          errorText[]{
            ${richtextFragment}
          }
        }`);
        setErrorData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [error]);

  return (
    errorData && (
      <ErrorPage
        icon={errorData.errorIcon.asset}
        text={errorData.errorText}
        error={error}
      />
    )
  );
}
