import { cx } from "@tracksuit/utils";
import { PortableText } from "@portabletext/react";
import { resolveLink } from "$src/lib/sanity";
import Link from "next/link";
import styles from "./callout.module.css";
import type { ComponentProps } from "react";

type CalloutProps = {
  color: "green" | "purple" | "red" | "pink";
  content: any;
  region: string;
} & ComponentProps<"div">;

/**
 * @component
 *
 */
export function Callout({
  className,
  content,
  region,
  color,
  ...props
}: CalloutProps) {
  return (
    <div
      className={cx(className, styles["container"], `--${color}`)}
      data-testid="callout"
      {...props}
    >
      <PortableText
        value={content}
        components={{
          marks: {
            link: ({ value, children }: any) => {
              return (
                <Link href={resolveLink(region, value)} className={styles.link}>
                  {children}
                </Link>
              );
            }
          }
        }}
      />
    </div>
  );
}
